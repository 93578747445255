import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cores"
    }}>{`Cores`}</h1>
    <p>{`O style system da Pismo fornece um conjunto de cores para manter a identidade visual da empresa. As cores estão inseridas no tema do bolt, a seguir exemplos de utilização:`}</p>
    <p>{`Para mudar a cor de um background ou de um texto, você adicionará uma classe com a sintaxe `}<strong parentName="p">{`bg-`}</strong>{` para o caso de um background ou `}<strong parentName="p">{`text-`}</strong>{` para cor do texto, seguido do prefixo da cor, `}<strong parentName="p">{`orange-`}</strong>{`, por exemplo, e por ultimo a tonalidade, `}<strong parentName="p">{`light`}</strong>{`.`}</p>
    <p><inlineCode parentName="p">{`bg-orange-light`}</inlineCode>{` ou `}<inlineCode parentName="p">{`text-orange-light`}</inlineCode></p>
    <h2 {...{
      "id": "primary-colors"
    }}>{`Primary colors`}</h2>
    <br />
    <Playground __position={1} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n    },\n    box: {\n      width: \'30px\',\n      height: \'30px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-2\">\n      <div style={style.container}>\n        <div className=\"tw-bg-gray\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-gray-95\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray-95</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-gray-90\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray-90</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-gray-75\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray-75</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-gray-50\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray-50</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-gray-25\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray-25</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-gray-10\" style={style.box}></div>\n        <p className=\"tw-text-white\">gray-10</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          box: {
            width: '30px',
            height: '30px',
            border: '1px solid white',
            borderRadius: '4px'
          }
        };
        return <div className="tw-flex tw-gap-2">
        <div style={style.container}>
          <div className="tw-bg-gray" style={style.box}></div>
          <p className="tw-text-white">gray</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-gray-95" style={style.box}></div>
          <p className="tw-text-white">gray-95</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-gray-90" style={style.box}></div>
          <p className="tw-text-white">gray-90</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-gray-75" style={style.box}></div>
          <p className="tw-text-white">gray-75</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-gray-50" style={style.box}></div>
          <p className="tw-text-white">gray-50</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-gray-25" style={style.box}></div>
          <p className="tw-text-white">gray-25</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-gray-10" style={style.box}></div>
          <p className="tw-text-white">gray-10</p>
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "secondary-colors"
    }}>{`Secondary colors`}</h2>
    <br />
    <Playground __position={4} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n    },\n    box: {\n      width: \'30px\',\n      height: \'30px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-2\">\n      <div style={style.container}>\n        <div className=\"tw-bg-orange\" style={style.box}></div>\n        <p className=\"tw-text-white\">orange</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-orange-dark\" style={style.box}></div>\n        <p className=\"tw-text-white\">orange-dark</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-orange-light\" style={style.box}></div>\n        <p className=\"tw-text-white\">orange-light</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-green\" style={style.box}></div>\n        <p className=\"tw-text-white\">green</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-green-dark\" style={style.box}></div>\n        <p className=\"tw-text-white\">green-dark</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-green-darker\" style={style.box}></div>\n        <p className=\"tw-text-white\">green-darker</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-green-light\" style={style.box}></div>\n        <p className=\"tw-text-white\">green-light</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          box: {
            width: '30px',
            height: '30px',
            border: '1px solid white',
            borderRadius: '4px'
          }
        };
        return <div className="tw-flex tw-gap-2">
        <div style={style.container}>
          <div className="tw-bg-orange" style={style.box}></div>
          <p className="tw-text-white">orange</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-orange-dark" style={style.box}></div>
          <p className="tw-text-white">orange-dark</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-orange-light" style={style.box}></div>
          <p className="tw-text-white">orange-light</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-green" style={style.box}></div>
          <p className="tw-text-white">green</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-green-dark" style={style.box}></div>
          <p className="tw-text-white">green-dark</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-green-darker" style={style.box}></div>
          <p className="tw-text-white">green-darker</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-green-light" style={style.box}></div>
          <p className="tw-text-white">green-light</p>
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "accent-colors"
    }}>{`Accent colors`}</h2>
    <br />
    <Playground __position={7} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n    },\n    box: {\n      width: \'30px\',\n      height: \'30px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-2\">\n      <div style={style.container}>\n        <div className=\"tw-bg-accent\" style={style.box}></div>\n        <p className=\"tw-text-white\">accent</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-accent-dark\" style={style.box}></div>\n        <p className=\"tw-text-white\">accent-dark</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-accent-light\" style={style.box}></div>\n        <p className=\"tw-text-white\">accent-light</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-accent-lighter\" style={style.box}></div>\n        <p className=\"tw-text-white\">accent-lighter</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          box: {
            width: '30px',
            height: '30px',
            border: '1px solid white',
            borderRadius: '4px'
          }
        };
        return <div className="tw-flex tw-gap-2">
        <div style={style.container}>
          <div className="tw-bg-accent" style={style.box}></div>
          <p className="tw-text-white">accent</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-accent-dark" style={style.box}></div>
          <p className="tw-text-white">accent-dark</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-accent-light" style={style.box}></div>
          <p className="tw-text-white">accent-light</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-accent-lighter" style={style.box}></div>
          <p className="tw-text-white">accent-lighter</p>
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "alert-colors"
    }}>{`Alert colors`}</h2>
    <br />
    <Playground __position={10} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n    },\n    box: {\n      width: \'30px\',\n      height: \'30px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-2\">\n      <div style={style.container}>\n        <div className=\"tw-bg-alert\" style={style.box}></div>\n        <p className=\"tw-text-white\">alert</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-alert-dark\" style={style.box}></div>\n        <p className=\"tw-text-white\">alert-dark</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-alert-light\" style={style.box}></div>\n        <p className=\"tw-text-white\">alert-light</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          box: {
            width: '30px',
            height: '30px',
            border: '1px solid white',
            borderRadius: '4px'
          }
        };
        return <div className="tw-flex tw-gap-2">
        <div style={style.container}>
          <div className="tw-bg-alert" style={style.box}></div>
          <p className="tw-text-white">alert</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-alert-dark" style={style.box}></div>
          <p className="tw-text-white">alert-dark</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-alert-light" style={style.box}></div>
          <p className="tw-text-white">alert-light</p>
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "neutral--basic-colors"
    }}>{`Neutral & Basic colors`}</h2>
    <br />
    <Playground __position={13} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n    },\n    box: {\n      width: \'30px\',\n      height: \'30px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-2\">\n      <div style={style.container}>\n        <div className=\"tw-bg-cream\" style={style.box}></div>\n        <p className=\"tw-text-white\">cream</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-black\" style={style.box}></div>\n        <p className=\"tw-text-white\">black</p>\n      </div>\n      <div style={style.container}>\n        <div className=\"tw-bg-white\" style={style.box}></div>\n        <p className=\"tw-text-white\">white</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          box: {
            width: '30px',
            height: '30px',
            border: '1px solid white',
            borderRadius: '4px'
          }
        };
        return <div className="tw-flex tw-gap-2">
        <div style={style.container}>
          <div className="tw-bg-cream" style={style.box}></div>
          <p className="tw-text-white">cream</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-black" style={style.box}></div>
          <p className="tw-text-white">black</p>
        </div>
        <div style={style.container}>
          <div className="tw-bg-white" style={style.box}></div>
          <p className="tw-text-white">white</p>
        </div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "text-colors"
    }}>{`Text colors`}</h2>
    <br />
    <Playground __position={16} __code={'() => {\n  const style = {\n    container: {\n      display: \'flex\',\n      flexDirection: \'column\',\n      alignItems: \'center\',\n      \'& p\': {\n        color: \'white\',\n      },\n    },\n    box: {\n      width: \'30px\',\n      height: \'30px\',\n      border: \'1px solid white\',\n      borderRadius: \'4px\',\n      backgroundColor: \'white\',\n      display: \'flex\',\n      justifyContent: \'center\',\n      alignItems: \'center\',\n      \'& h1\': {\n        fontFamily: \'Lato\',\n        fontWeight: \'bolder\',\n      },\n    },\n  }\n  return (\n    <div className=\"tw-flex tw-gap-2\">\n      <div style={style.container}>\n        <div style={style.box}>\n          <h1 className=\"tw-text-black\">T</h1>\n        </div>\n        <p>black</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box}>\n          <h1 className=\"tw-text-black-75\">T</h1>\n        </div>\n        <p>black-75</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box}>\n          <h1 className=\"tw-text-black-50\">T</h1>\n        </div>\n        <p>black-50</p>\n      </div>\n      <div style={style.container}>\n        <div style={style.box}>\n          <h1 className=\"tw-text-black-25\">T</h1>\n        </div>\n        <p>black-25</p>\n      </div>\n      <div style={style.container}>\n        <div style={{ ...style.box, backgroundColor: \'black\' }}>\n          <h1 className=\"tw-text-white\">T</h1>\n        </div>\n        <p>white</p>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const style = {
          container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& p': {
              color: 'white'
            }
          },
          box: {
            width: '30px',
            height: '30px',
            border: '1px solid white',
            borderRadius: '4px',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& h1': {
              fontFamily: 'Lato',
              fontWeight: 'bolder'
            }
          }
        };
        return <div className="tw-flex tw-gap-2">
        <div style={style.container}>
          <div style={style.box}>
            <h1 className="tw-text-black">T</h1>
          </div>
          <p>black</p>
        </div>
        <div style={style.container}>
          <div style={style.box}>
            <h1 className="tw-text-black-75">T</h1>
          </div>
          <p>black-75</p>
        </div>
        <div style={style.container}>
          <div style={style.box}>
            <h1 className="tw-text-black-50">T</h1>
          </div>
          <p>black-50</p>
        </div>
        <div style={style.container}>
          <div style={style.box}>
            <h1 className="tw-text-black-25">T</h1>
          </div>
          <p>black-25</p>
        </div>
        <div style={style.container}>
          <div style={{ ...style.box,
              backgroundColor: 'black'
            }}>
            <h1 className="tw-text-white">T</h1>
          </div>
          <p>white</p>
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      